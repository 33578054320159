* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #424E5C;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 1280px) {
  body {
    font-size: 12px;
  }
}

#root {
  width: 100%;
  height: 100%;
}

a,
a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

button:focus {
  outline:0;
}
