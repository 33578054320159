.list-item {
  display: inline-flex;
  width: 100%;

  margin-bottom: 20px;
}

.list-item-checkmark {
  height: 20px;
  margin-top: 6px;
  margin-right: 10px;
}

.list-item > span {
  letter-spacing: 0.8px;
  font-weight: 600;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .list-item {
    width: 49%;
    min-height: 80px;
    margin-left: -20px;
  }
}

@media screen and (min-width: 1024px) {
  .list-item-checkmark {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1280px) {
  .list-item {
    margin-left: -41px;
  }

  .list-item-checkmark {
    height: 24px;
    margin-right: 15px;
  }
}
