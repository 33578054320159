.social-icon {
  display: inline-block;
  height: 40px;
  margin-right: 10px;

  opacity: 0.7;
  transition: all .2s cubic-bezier(.25,1.6,.6,1.4);
  transform: scale(1);
}

.social-icon:hover {
  opacity: 1;
  transition: all .2s cubic-bezier(.25,1.6,.6,1.4);
  transform: scale(1.1);
}

@media screen and (min-width: 1280px) {
  .social-icon {
    height: 44px;
    margin-right: 15px;
  }
}
