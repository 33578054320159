.about i {
  color: rgb(152, 240, 189);
}

#portrait {
  display: block;
  height: 110px;

  float: right;
  margin-bottom: 0;
  margin-left: 25px;

  border: solid 0.3px rgba(236, 239, 223, 0.452);
  filter: saturate(1.2) brightness(0.95);
}

.cta-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

@media screen and (min-width: 667px) {
  #portrait {
    height: 140px;
    margin-left: 35px;
  }

  .cta-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  #portrait {
    height: 180px;
    margin-left: 25px;
  }

  .cta-buttons {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  #portrait {
    height: 140px;

    margin-left: 20px;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.116);
  }

  .cta-buttons {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  #portrait {
    right: 55px;
    height: 13vw;
    max-height: 190px;
  }

  .cta-buttons {
    margin: 40px 0;
  }
}
