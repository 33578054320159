.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 65px);

  padding: 30px 10px;
  margin-top: 65px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  background-color: #80868E;
}

.page::-webkit-scrollbar {
  width: 0 !important
}

@media screen and (min-width: 667px) {
  .page {
    height: calc(100% - 60px);
    padding: 40px 50px;
    margin-top: 60px;
  }
}

@media screen and (min-width: 768px) {
  .page {
    height: calc(100% - 65px);
    padding: 50px 60px;
    margin-top: 65px;
  }
}

@media screen and (min-width: 1024px) {
  .page {
    height: 100%;

    padding: 120px 100px 60px 50px;
    overflow-y: scroll;
    margin-top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .page {
    padding: 120px;
  }
}
