.navbar-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  min-height: 65px;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;

  background-color: #FFFFFF;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.navbar-sm.open {
  background-color: #424E5C;
}

.navbar-sm.open > .page-title {
  color: #FFFFFF;
}

.navbar-sm > .home-title {
  color: #424E5C;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  font-size: 17px;
  font-weight: 500;
}

.navbar-sm.open > .home-title {
  color: #FFFFFF;
}

#timo-img {
  height: 40px;
  border-radius: 50px;
  border: 0.5px solid rgba(112, 112, 112, 0.8);
  box-shadow: 0 1px 2px rgba(13, 10, 10, 0.35);
}

.menu-btn {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.menu-btn.open {
  transform: rotate(90deg);
}

.navbar-sm-nav {
  width: 100%;
  height: calc(100vh - 65px);

  position: absolute;
  top: 65px;
  right: 0;

  padding-top: 70px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  background-color: #424E5C;
}

.navbar-sm-bottom {
  margin-top: auto;
  margin-bottom: 60px;
  margin-right: 20px;

  color: #FFFFFF;
  font-size: 14px;
  text-align: right;
  line-height: 30px;
}

.navbar-sm-nav > a.nav-sm-link {
  display: block;
  width: 75%;
  max-width: 300px;

  padding-right: 30px;

  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.211);

  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  text-align: right;
  line-height: 70px;
  color: #FFFFFF;

  border-radius: 15px 0 0 15px;
}

.navbar-sm-nav > a.babyblue {
  background-color: #86A5C1;
  z-index: 9;
}

.navbar-sm-nav > a.seagreen {
  background-color: #62ACA0;
  z-index: 8;
}

.navbar-sm-nav > a.paleorange {
  background-color: #DDA267;
  z-index: 7;
}

.navbar-sm-nav > a.cherry {
  background-color: #A74965;
  z-index: 6;
}

@media screen and (min-width: 667px) {
  .navbar-sm {
    min-height: 60px;
  }

  .navbar-sm-nav {
    height: calc(100vh - 60px);

    top: 60px;
    padding-top: 0;
    padding-bottom: 20px;
  }

  .navbar-sm-nav > a.nav-sm-link {
    font-size: 19px;
    line-height: 60px;
  }

  .navbar-sm-bottom {
    align-self: flex-start;
    margin-left: 20px;
    margin-right: 0;
    margin-bottom: 10px;
    line-height: 22px;
    text-align: left;

    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media screen and (min-width: 768px) {
  .navbar-sm {
    min-height: 65px;
  }

  .navbar-sm-nav {
    padding-top: 100px;
    padding-bottom: 0;
    justify-content: flex-start;
    top: 65px;
  }

  .navbar-sm-bottom {
    align-self: flex-end;
    margin-right: 20px;
    margin-left: 0;
    margin-bottom: 50px;
    line-height: 30px;
    text-align: right;

    position: initial;
  }
}

@media screen and (min-width: 1024px) {
  .navbar-sm {
    display: none;
  }
}
