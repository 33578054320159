.navbar {
  display: none;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;

  position: absolute;
  right: 50px;
  top: 35px;

  border-radius: 15px;
  background-color: #424E5C;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 6;

  cursor: pointer;

  transition: transform .2s cubic-bezier(.25,1.6,.6,1.4);
  transform: scale(1);
}

.navbar:hover {
  transform: scale(1.05);
}

.navbar.open {
  height: 360px;
  align-items: flex-start;
  padding: 20px 0;

  border-radius: 15px;
}

.navbar.open:hover {
  transform: none;
}

.navbar-icon {
  position: relative;
  width: 18px;
  margin-right: 2px;
}

.navbar-icon.open {
  transform: rotate(90deg);
}

.nav-items {
  display: none;
  opacity: 0;
  position: absolute;
  top: 150px;
  right: 0;
}

.nav-items.open {
  display: block;
  opacity: 1;
}

.nav-items > a.nav-link {
  display: block;
  width: 160px;

  padding: 7px 15px;

  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.211);

  font-size: 18px;
  font-weight: 800;
  font-style: italic;
  line-height: 30px;
  text-align: left;
  color: #FFFFFF;
}

.nav-items > a.babyblue {
  background-color: #86A5C1;
  z-index: 9;
}

.nav-items > a.seagreen {
  background-color: #62ACA0;
  z-index: 8;
}

.nav-items > a.paleorange {
  background-color: #DDA267;
  z-index: 7;
}

.nav-items > a.cherry {
  background-color: #A74965;
  z-index: 6;
}

@media screen and (min-width: 1024px) {
  .navbar {
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .navbar {
    height: 55px;
    width: 55px;
  }

  .navbar.open {
    height: 445px;
  }

  .navbar-icon {
    width: 23px;
    margin-right: 3px;
  }

  .nav-items {
    top: 190px;
  }

  .nav-items > a.nav-link {
    font-size: 20px;
    line-height: 32px;
    padding: 9px 17px;
  }
}

