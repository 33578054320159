.sidebar {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 210px;

  position: relative;
  padding-left: 35px;

  background-color: #FFFFFF;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

  z-index: 2;

  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  line-height: 24px;
  color: #424E5C;
}

.sidebar-mid {
  margin-top: auto;
  margin-bottom: auto;
}

.sidebar-mid > a:nth-of-type(3) > .social-icon {
  margin-right: 0;
}

.sidebar-title {
  font-size: 16px;
  font-weight: 600;
}

.sidebar-subtitle {
  font-size: 14px;
  font-weight: 400;

  margin: 39px 0;
}

.sidebar-bottom {
  margin-bottom: 25px;

  line-height: 20px;
}

@media screen and (min-width: 1024px) {
  .sidebar {
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .sidebar {
    min-width: 240px;
  }

  .sidebar-title {
    font-size: 18px;
  }

  .sidebar-subtitle {
    font-size: 16px;
  }
}
