.service-boxes {
  width: 100%;
}

.service-boxes > .box {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px;
}

.service-interlude {
  align-self: flex-start;

  color: #FFFFFF;
  font-size: 16px;
  margin: 50px 0 30px 0;
}

.service-interlude > b {
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .service-boxes > .box {
    width: 46%;
    margin-bottom: 40px;
  }

  .service-boxes > .box:nth-child(even) {
    float: right;
  }

  .service-interlude {
    margin: 60px 0 45px 0;
  }

  .services .list-item:nth-of-type(even) {
    float: right;
  }
}

@media screen and (min-width: 1024px) {
  .service-boxes {
    max-width: 690px;
  }

  .service-boxes > .box {
    width: 48%;
    max-width: 350px;
  }

  .service-interlude {
    width: 100%;
    max-width: 680px;
    align-self: center;
    text-align: left;
  }
}

@media screen and (min-width: 1280px) {
  .service-boxes {
    max-width: 850px;
  }

  .service-boxes > .box {
    max-width: unset;
  }

  .service-interlude {
    max-width: 850px;
    font-size: 19px;
  }
}
