.box {
  width: 100%;
  position: relative;
}

.box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 55px;

  z-index: 2;
  background-color: #F8F8F8;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  padding: 10px 10px 10px 20px;
}

.box-title {
  display: inline;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #1B3A5D;
}

.box-image {
  display: block;
  height: auto;
  border-radius: 15px;
}

.box-image.fixed {
  max-width: 80px;
  max-height: 45px;
  width: auto;
  height: auto;
}

.box-image.floating {
  width: 50px;

  position: absolute;
  right: 20px;
  top: 15px;

  border-radius: 0;

  filter: saturate(0.8) drop-shadow(0 3px 5px rgba(0, 0, 0, 0.11));
}

.box-image.none {
  display: none;
}

.box-content {
  width: 100%;

  background-color: rgba(62, 62, 62, 0.21);

  padding: 20px;

  font-family: 'Raleway', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
  color: #FFFFFF;
}

@media screen and (min-width: 768px) {
  .box-header {
    min-height: 65px;
    padding: 10px 15px 10px 30px;
  }

  .box-image.fixed {
    max-width: 110px;
    max-height: 65px;
  }

  .box-image.floating {
    width: 60px;
    right: 25px;
    top: 15px;
  }

  .box-content {
    padding: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .box {
    max-width: 690px;
  }

  .box-header {
    height: 70px;
    padding: 10px 15px 10px 40px;
  }

  .box-title {
    font-size: 17px;
  }

  .box-image.fixed {
    max-height: 60px;
  }

  .box-content {
    font-size: 15.5px;
    padding: 35px 40px;
  }
}

@media screen and (min-width: 1280px) {
  .box {
    max-width: 850px;
  }

  .box-header {
    height: 80px;
    padding: 10px 20px 10px 60px;
  }

  .box-title {
    font-size: 19px;
  }

  .box-content {
    font-size: 16.5px;
    padding: 35px 50px 35px 60px;
    overflow: auto;
  }

  .box-image.floating {
    width: 65px;
    top: 25px;
    right: 40px;
  }
}
