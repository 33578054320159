.attributions {
  justify-content: flex-start;
}

.attributions img {
  max-height: 45px;
  max-width: 45px;
  height: auto;
  width: auto;

  margin-right: 15px;
}
