.work > .box {
  margin-bottom: 60px;
}

.box-content-left {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.screenshot-text {
  display: block;
  margin-bottom: 10px;

  font-size: 9px;
  color: #9EAAB7;
  text-align: center;
}

@media screen and (min-width: 667px) {
  .screenshot {
    height: 600px;
  }
}

@media screen and (min-width: 768px) {
  .screenshot {
    height: 550px;
  }
}

@media screen and (min-width: 1024px) {
  .box-content-left {
    width: 47%;
  }

  .work .image-carousel {
    display: inline-block;
    vertical-align: top;
    /* width: 45%;
    height: auto;

    margin-top: 0;
    margin-left: 40px; */
  }

  .work > .box:first-of-type > .box-content {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .screenshot {
    margin-top: -20px;
  }
}
