.social-btn {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  opacity: 0.6;
}

.social-btn:hover {
  opacity: 0.9;
  cursor: pointer;
}

.social-btn > img {
  height: 15px;
  margin-right: 5px;
}

.social-btn > span {
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: #424E5C;
}

@media screen and (min-width: 1280px) {
  .social-btn {
    margin-bottom: 0;
  }

  .social-btn > img {
    width: 20px;
    margin-right: 8px;
  }

  .social-btn > span {
    font-size: 14px;
    font-weight: 400;
  }
}
