.page-title {
  font-size: 24px;
  line-height: 29px;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  font-style: italic;
  font-weight: 800;
  color: #DBDADA;
}

.page-title.seagreen {
  color: #62ACA0;
}

.page-title.paleorange {
  color: #DDA267;
}

.page-title.cherry {
  color: #A74965;
}

@media screen and (min-width: 1280px) {
  .page-title {
    align-self: flex-start;
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 70px;
  }
}
