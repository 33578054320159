.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media screen and (min-width: 1024px) {
  .app {
    flex-direction: row;
  }
}

.app > div:nth-child(3) {
  flex: 5;
  position: relative;
}
