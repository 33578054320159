.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

#welcome {
  margin-top: auto;
  position: relative;

  padding: 0 15px;
}

.welcome-gray {
  font-family: 'Raleway', 'Helvetica Neue', sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #FFFFFF;
  text-shadow: 1px 2px 3px rgba(45, 46, 59, 0.082);
  font-style: normal;
}

.welcome-yellow {
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  font-size: 32px;
  font-weight: 800;
  font-style: italic;
  color: #F2E187;
  text-shadow: -0.5px 0 #E2CD33, 0 0.5px #E2CD33, 0.5px 0 #E2CD33, 0 -0.5px #E2CD33, 0 1px 1px rgba(45, 46, 59, 0.178);
}

.home-arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  float: right;
  margin: 20px 0;

  opacity: 0.9;
}

#home-arrow {
  height: 115px;
  width: auto;
}

#home-arrow-text {
  width: 180px;

  color: rgb(243, 233, 233);
  font-size: 12px;
  text-align: right;

  margin-top: 15px;
  margin-left: 50px;
}

.home-socials {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 25px;
}

.home-socials > .social-btn {
  margin: 0 10px;
}

.home-socials > .social-btn > span {
  font-family: 'Raleway', 'Arial', sans-serif;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 16px;
}

@media screen and (min-width: 667px) {
  .home {
    justify-content: flex-start;
  }

  #welcome {
    margin-bottom: 50px;
    max-width: auto;
  }

  .home-arrow-container {
    float: none;

    position: absolute;
    right: -135px;
    bottom: -80px;
  }

  #home-arrow {
    transform: rotate(-10deg);
    height: 100px;
  }

  #home-arrow-text {
    width: 200px;
    margin-top: 0;
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .home {
    justify-content: center;
  }

  #welcome {
    margin-bottom: 0;
  }

  .welcome-yellow {
    font-size: 50px;
    text-shadow: -1px 0 #E2CD33, 0 1px #E2CD33, 1px 0 #E2CD33, 0 -1px #E2CD33, 0 3px 5px rgba(45, 46, 59, 0.178);
  }

  .welcome-gray {
    font-size: 38px;
  }

  .home-arrow-container {
    position: unset;
    float: right;
  }

  #home-arrow {
    height: 170px;
    transform: none;
  }

  #home-arrow-text {
    margin-top: 15px;
  }

  .home-socials {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }

  .home-socials > .social-btn {
    margin: 0 20px;
  }
}

@media screen and (min-width: 1024px) {
  .home {
    padding: 0;
  }

  #welcome {
    margin-top: 80px;
  }

  .welcome-yellow {
    font-size: 50px;
    text-shadow: -0.5px 0 #E2CD33, 0 0.5px #E2CD33, 0.5px 0 #E2CD33, 0 -0.5px #E2CD33, 0 3px 5px rgba(45, 46, 59, 0.178);
  }

  .welcome-gray {
    font-size: 40px;
  }

  .home-socials {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  #welcome {
    margin-top: 20vh;
  }

  .welcome-yellow {
    font-size: 62px;
    text-shadow: -0.5px 0 #E2CD33, 0 0.5px #E2CD33, 0.5px 0 #E2CD33, 0 -0.5px #E2CD33, 0 1px 1px rgba(45, 46, 59, 0.178);
  }

  .welcome-gray {
    font-size: 50px;
  }

  .home-arrow-container {
    margin-right: -100px;
  }

  #home-arrow {
    height: 20vh;
  }

  #home-arrow-text {
    width: 220px;
    font-size: 14px;
  }
}
