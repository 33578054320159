.cta-button {
  display: inline-block;
  width: 220px;
  margin: 20px 25px;
  padding: 15px 10px;

  background-color: transparent;
  font-family: 'Raleway', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  color: #93FFC0;

  border: 1.5px solid rgba(147, 255, 192, 0.74);
  border-radius: 5px;
  cursor: pointer;

  transition: all .2s cubic-bezier(.25,1.6,.6,1.4);
  transform: scale(1);
}

.cta-button:hover {
  background-color: rgba(147, 255, 192, 0.459);
  color: white;

  transform: scale(1.05);
}

@media screen and (min-width: 667px) {
  .cta-button {
    width: 220px;
  }
}

@media screen and (min-width: 1024px) {
  .cta-button {
    width: 225px;
  }
}

@media screen and (min-width: 1280px) {
  .cta-button {
    font-size: 13.5px;
  }
}
